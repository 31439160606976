import { translations, language, languages } from './translations';

const THEMES = ['light', 'dark'];
const reset_sms_link = '/reset-sms';
const COOKIE_POLICY_URL = {
    pl: 'https://www.atman.pl/Polityka-plikow-cookies-cabout-pol-94.html',
    en: 'https://www.atman.pl/Cookies-policy-cabout-eng-94.html',
    ru: 'https://www.atman.pl/Cookies-policy-cabout-eng-94.html'
};
const PRIVATE_POLICY_URL = {
    pl: 'https://www.atman.pl/Polityka-prywatnosci-cabout-pol-93.html',
    en: 'https://www.atman.pl/Privacy-policy-cabout-eng-93.html',
    ru: 'https://www.atman.pl/Privacy-policy-cabout-eng-93.html'
};

const REGULATIONS = {
    pl: 'https://www.atman.pl/regulamin-strefy-klienta-atman/',
    en: 'https://www.atman.pl/atman-customer-zone-rules/',
    ru: 'https://www.atman.pl/atman-customer-zone-rules/'
};

let lang = languages[languages.indexOf(language.substring(0, 2))] || 'pl';
let theme = 'dark';
let currentProgressValue = 100;
let errorCode;

function translate(lang, errorCode) {
    if (translations[lang]) {

        // welcome text 
        $('.welcome-text').text(translations[lang].welcome_text);

        // Form labels
        $('label[for="username"]').text(translations[lang].login);
        $('label[for="login"]').text(translations[lang].login);
        $('label[for="password"]').text(translations[lang].password);
        $('label[for="userEmail"]').text(translations[lang].email);
        $('.sr-only').text(translations[lang].menu_select);

        // Languages control
        $('#languageFormControl').attr('title', translations[lang].lang_select);

        // Login front-office
        $('.title').text(translations[lang].title);
        $('.login-form__buttons--submit').text(translations[lang].submit);

        // Login back-office
        $('.title.administration').text(translations[lang].title_backoffice);

        // Reset password
        $('.reset-password-btn').text(translations[lang].reset_password_btn);
        $('.reset-password__hint--info .reset-password__hint-text').text(translations[lang].reset_password_hint);
        $('.reset-password-form__buttons--login').text(translations[lang].back_to_login_btn);
        $('.reset-password-form__buttons--submit').text(translations[lang].submit_reset);
        $('.title.reset-password').text(translations[lang].title_reset_password);

        // 2Fa authentication
        $('.title-2fa').text(translations[lang].twofa_title);
        $('label[for="code"]').text(translations[lang].twofa_label);
        $('.reset-twofa-form__buttons--submit').text(translations[lang].twofa_submit);

        if (currentProgressValue >= 100) {
            $('.reset-sms-btn').text(translations[lang].twofa_reset_btn);
        } else {
            setRemainingTimeInfo();
        }

        // Form fields
        if ($('.invalid-feedback.username').text() != '') {
            if ($('.invalid-feedback.username').data('type') == 'empty') {
                $('.invalid-feedback.username').text(translations[lang].empty_control);
            } else {
                $('.invalid-feedback.username').text(translations[lang].email_error);
            }
        }

        if ($('.invalid-feedback.password').text() != '') {
            if ($('.invalid-feedback.password').data('type') == 'empty') {
                $('.invalid-feedback.password').text(translations[lang].empty_control);
            }
        }

        if ($('.invalid-feedback.code').text() != '') {
            if ($('.invalid-feedback.code').data('type') == 'empty') {
                $('.invalid-feedback.code').text(translations[lang].empty_control);
            }
        }

        $('body.error-500 .main-text').text(translations[lang].error_500);
        $('body.error-500 .additional-text').text(translations[lang].error_500_support);
        $('body.error-404 .main-text').text(translations[lang].error_404);
        $('body.error-login .main-text').text(translations[lang].error_login);
        $('body.error-login .additional-text').text(translations[lang].error_login_support);
        $('body.error-invalid-token .main-text').text(translations[lang].invalid_token_title);
        $('body.error-invalid-token .additional-text').text(translations[lang].invalid_token_content);

        // Cookies approval

        $('#cookie_policy_text_I').text(translations[lang].cookie_policy_text_I);
        $('#cookie_policy_text_url').attr('href', COOKIE_POLICY_URL[lang]);
        $('#cookie_policy_text_url').text(translations[lang].cookie_policy_text_url);
        $('#cookie_policy_text_II').text(translations[lang].cookie_policy_text_II);

        $('#privacy_policy_text_I').text(translations[lang].privacy_policy_text_I);
        $('#privacy_policy_text_url').text(translations[lang].privacy_policy_text_url);
        $('#privacy_policy_text_url').attr('href', PRIVATE_POLICY_URL[lang]);
        $('#privacy_policy_text_II').text(translations[lang].privacy_policy_text_II);

        $('#cookie_configuration').text(translations[lang].cookie_configuration);

        $('#cookie_type_technical_label').text(translations[lang].cookie_type_technical_label);
        $('#cookie_type_advertisement_label').text(translations[lang].cookie_type_advertisement_label);
        $('#cookie_type_functional_label').text(translations[lang].cookie_type_functional_label);
        $('#cookie_type_analytics_label').text(translations[lang].cookie_type_analytics_label);
        $('#cookie_type_technical_info').text(translations[lang].cookie_type_technical_info);
        $('#cookie_type_advertisement_info').text(translations[lang].cookie_type_advertisement_info);
        $('#cookie_type_functional_info').text(translations[lang].cookie_type_functional_info);
        $('#cookie_type_analytics_info').text(translations[lang].cookie_type_analytics_info);

        $('#cookie_add_info').text(translations[lang].cookie_add_info);

        $('#cookie_button_text').text(translations[lang].cookie_button_text);

        // Footer
        $('#contact_info_main_text').text(translations[lang].contact_info.main_text);
        $('#contact_info_email').text(translations[lang].contact_info.email);
        $('#contact_info_phone').text(translations[lang].contact_info.phone);
        $('#contact_info_fax').text(translations[lang].contact_info.fax);
        $('#contact_info_regulations_url').attr('href', REGULATIONS[lang]);
        $('#contact_info_regulations_text').text(translations[lang].contact_info.regulations_text);

        translateByErrorCode(lang, errorCode)
    }
}

function setCurrentBarValue(currentVal) {
    var time_remaining = parseInt(30 * (1 - currentVal / 100), 10);
    $('.progress-bar').css({ width: currentVal + '%' });
    $('.progress-bar').attr('aria-valuenow', currentVal);
    $('.time-remaining').text(time_remaining);
}

function setLinkDisabled() {
    $('.reset-sms-btn').addClass('disabled');
    $('.reset-sms-btn').removeAttr('href');
    setRemainingTimeInfo();
}

function setRemainingTimeInfo() {
    $('.reset-sms-btn').html(
        translations[lang].twofa_reset_btn_time +
        '<span class="time-remaining">' +
        parseInt(currentProgressValue, 10) +
        '</span>' +
        translations[lang].twofa_reset_btn_time_end
    );
}

function translateByErrorCode(lang, errorCode) {
    if (errorCode) {
        // 2FA screen
        if ($('.reset-sms-btn').length) {
            $('.reset-twofa-form__error__text').data('type', 'code');
            $('.reset-twofa-form__error__text').text(translations[lang][errorCode]);
            $('.reset-twofa-form__error').show();
            $('.material-icons.error').show();
        } else {
            $('.login-form__error__text').text(translations[lang][errorCode]);
            $('.material-icons.error').show();
            $('.login-form__error').show();
        }

        // Account error
        if ($('.account-error').length) {
            switch (errorCode) {
                case 'authentication_failure_user_ip_does_not_match_ip_whitelist':
                    $('.blocked-account').show();
                    $('.blocked-account .title').text(translations[lang].title_access_denied);
                    $('.blocked-account .content').text(translations[lang].ip_whitelist_issue_content);
                    $('.blocked-account .redirect-btn').text(translations[lang].ip_whitelist_issue_redirect_btn);
                    break;
                case 'authentication_failure_account_suspended':
                    $('.suspended-account').show();
                    $('.suspended-account .title').text(translations[lang].title_suspended_account);
                    $('.suspended-account .content').text(translations[lang].suspended_account_content);
                    $('.suspended-account .redirect-btn').text(translations[lang].suspended_account_redirect_btn);
                    break;
                default:
                    break;
            }
        }
    }
}

function setupCookiesInformation() {
    if ($.cookie("cookie_consent")) {
        hideCookiesInformation();
    } else {
        $('.btn-accept-cookies').click(function () {
            const technical_cookie = $("input#technical_cookie").is(':checked');
            const advertisement_cookie = $("input#advertisement_cookie").is(':checked');
            const analytics_cookie = $("input#analytics_cookie").is(':checked');

            const value = {technical: technical_cookie, advertisement: advertisement_cookie, analytics: analytics_cookie, date: new Date()};
            $.cookie('cookie_consent', JSON.stringify(value), { expires: 365, path: '/' });
            hideCookiesInformation();
        });
    }
}

function hideCookiesInformation() {
    $('.cookie-container').remove();
}

function setTheme(newTheme) {
    $('.theme-btn.' + theme)
        .parent()
        .removeClass('active');

    theme = newTheme;

    $('.theme-btn.' + theme)
        .parent()
        .addClass('active');

    THEMES.forEach(function (thm) {
        $('body').removeClass('theme-' + thm);
    });

    $('body').addClass('theme-' + theme);

    $.cookie('usr_theme', theme, { expires: 14, path: '/' });
}

function setupTheme() {
    if ($.cookie("usr_theme") && $.cookie("usr_theme") !== theme) {
        setTheme($.cookie('usr_theme'));
    } else {
        setTheme(theme);
    }

    $('.theme-list .theme-btn').click(function () {
        const navLink = $(this);

        THEMES.forEach(function (thm) {
            if (navLink.hasClass(thm)) {
                setTheme(thm);
            }
        });

    });
}

$(document).ready(function () {
    errorCode = $('.BErrorCode').text();

    if ($.cookie("usr_lang") && $.cookie("usr_lang") !== lang) {
        lang = $.cookie('usr_lang');
        translate(lang);
    } else {
        translate(lang);
    }

    $.cookie('usr_lang', lang, { expires: 14, path: '/' });

    // cookie approval

    setupCookiesInformation();


    // theme
    setupTheme();

    $('.login-form__error').hide();

    // ekran 2FA
    if ($('.reset-sms-btn').length) {
        $('.reset-twofa-form__error').hide();

        if (currentProgressValue < 100) {
            setLinkDisabled();
            $('.progress-sms').show();
        } else {
            $('.progress-sms').hide();
        }

        setInterval(function () {
            if (currentProgressValue < 100) {
                currentProgressValue = currentProgressValue + 0.666667;
                setCurrentBarValue(currentProgressValue);
                $('.progress-sms').show();
            } else {
                $('.reset-sms-btn').removeClass('disabled');
                $('.reset-sms-btn').attr('href', reset_sms_link);
                if ($('.reset-sms-btn').text() !== translations[lang].twofa_reset_btn) {
                    $('.reset-sms-btn').text(translations[lang].twofa_reset_btn);
                }
                $('.progress-sms').hide();
            }
        }, 200);

        $('.reset-sms-btn').click(function () {
            $('.reset-twofa-form__error').hide();
            $('.reset-password-form__error__text').text('');
            $('.reset-twofa-form__error__text').data('type', '');

            currentProgressValue = 0;
            setLinkDisabled();
            $.ajax({
                type: 'POST',
                contentType: 'application/json',
                url: '2fa/resend',
                data: null,
                success: function () {
                    $('.reset-sms-btn').hide();
                    $('.progress-bar').hide();

                    $('.reset-sms-code-sent').text(
                        translations[lang].twofa_code_sent
                    );
                    $('.reset-sms-code-sent').show();
                    $('.code-sent').show();

                    errorCode = null;

                    setTimeout(function () {
                        $('.reset-sms-code-sent').hide();
                        $('.code-sent').hide();
                        $('.reset-sms-code-sent').text('');

                        $('.reset-sms-btn').show();
                        $('.progress-bar').show();
                    }, 3000);
                },
                error: function (error) {
                    $('.reset-twofa-form__error__text').text(
                        translations[lang][error.message]
                    );
                    $('.reset-twofa-form__error__text').data('type', 'request');
                    $('.reset-twofa-form__error').show();
                    $('.material-icons.error').show();
                }
            });
        });
    }

    var form = document.querySelector('.login-form__form');

    if (errorCode != '') {
        translate(lang, errorCode);
    }

    $('.language-btn.' + lang)
        .parent()
        .addClass('active');
    $('#languageFormControl').val(lang);

    $('.login-form__buttons--logout').click(function () {
        location.reload();
    });

    $('.language-list .language-btn').click(function () {
        $('.language-btn.' + lang)
            .parent()
            .removeClass('active');
        if ($(this).hasClass('pl')) {
            lang = 'pl';
            translate('pl', errorCode);
        }
        if ($(this).hasClass('en')) {
            lang = 'en';
            translate('en', errorCode);
        }
        if ($(this).hasClass('ru')) {
            lang = 'ru';
            translate('ru', errorCode);
        }

        $.cookie('usr_lang', lang, { expires: 14, path: '/' });

        $('.language-btn.' + lang)
            .parent()
            .addClass('active');
        $('#languageFormControl').val(lang);
        $('input#lang').val(lang);
        $('.login-form__error__text').text(translations[lang].login_fail);
        // ekran 2FA
        if ($('.reset-sms-btn').length) {
            var error = $('.reset-twofa-form__error__text').data('type') == 'code' ? translations[lang].twofa_reset_error_bad_code : translations[lang].twofa_reset_error_bad_request;
            $('.reset-twofa-form__error__text').text(error);
            if ($('.reset-sms-code-sent').text() !== '') {
                $('.reset-sms-code-sent').text(translations[lang].twofa_code_sent);
            }
        }
    });

    $('#languageFormControl').on('change', function () {
        $('.nav-link.' + lang)
            .parent()
            .removeClass('active');

        if ($(this).val() == 'pl') {
            lang = 'pl';
            translate('pl', errorCode);
        }
        if ($(this).val() == 'en') {
            lang = 'en';
            translate('en', errorCode);
        }
        if ($(this).val() == 'ru') {
            lang = 'ru';
            translate('ru', errorCode);
        }

        $.cookie('usr_lang', lang, { expires: 14, path: '/' });
        $('.nav-link.' + lang)
            .parent()
            .addClass('active');
        $('input#lang').val(lang);
        $('.login-form__error__text').text(translations[lang][errorCode]);
        // ekran 2FA
        if ($('.reset-sms-btn').length) {

            $('.reset-twofa-form__error__text').text(translations[lang][errorCode]);

            if ($('.reset-sms-code-sent').text() !== '') {
                $('.reset-sms-code-sent').text(translations[lang].twofa_code_sent);
            }
        }
    });

    $("input[name!='code']").keypress(function () {
        $('.login-form__error').hide();
        $(this)
            .next()
            .hide();
    });

    $('#username, #password').on('invalid', function (event) {
        var controlName = $(this).attr('name');
        var message = '';

        if (event.target.validity.valueMissing || controlName == 'password') {
            if (controlName == 'password') {
                $('.invalid-feedback.password').data('type', 'empty');
            } else {
                $('.invalid-feedback.username').data('type', 'empty');
            }
            message = translations[lang].empty_control;
        } else {
            $('.invalid-feedback.username').data('type', 'email');
            message = translations[lang].email_error;
        }

        $('.invalid-feedback.' + controlName)
            .text(message)
            .show();
    });

    $('#code').on('invalid', function (event) {
        var controlName = $(this).attr('name');
        var message = '';

        if (event.target.validity.valueMissing) {
            $('.invalid-feedback.code').data('type', 'empty');
            message = translations[lang].empty_control;
        }

        $('.invalid-feedback.' + controlName)
            .text(message)
            .show();
    });

    $('.login-form__form').submit(function (event) {
        $('.login-form__error').hide();
        $('.invalid-feedback').hide();
        const inputUsername = $("input#username");
        const trimmedUsername = $.trim(inputUsername.val());
        inputUsername.val(trimmedUsername);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }

        return true;
    });

    // 2FA LOGIN -----------------------------------------------------

    $('.reset-twofa-form__form').submit(function (event) {
        var form = document.querySelector('.reset-twofa-form__form');
        $('.reset-password-form__error__text').text('');
        $('.reset-twofa-form__error__text').data('type', '');
        $('.reset-twofa-form__error').hide();
        $('.invalid-feedback').hide();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }

        return true;
    });

    // RESET PASSWORD -----------------------------------------------------

    // Reset password form
    $('.reset-password-form__form').submit(function (event) {
        event.preventDefault();
        $('.reset-password-form__error').removeClass('d-flex');
        $('.login-form__error').hide();
        $('.invalid-feedback').hide();
        $('.reset-password__hint--info').show();

        if (event.target.checkValidity() === false) {
            return false;
        }

        var resetForm = {
            username: $.trim(event.target.username.value),
            lang: lang
        };

        sendRequest(resetForm);
    });

    // Ajax request method
    function sendRequest(value) {
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: '/uaa/reset-password',
            data: JSON.stringify(value),
            success: function () {
                $('.reset-password-form__buttons--submit').hide();
                $('.reset-password__hint--info').hide();
                $('.reset-password-form__error').removeClass('d-flex');
                $('.reset-form').hide();
                $('.reset-password__hint--success .reset-password__hint-text').text(
                    translations[lang].reset_password_success
                );
                $('.reset-password__hint--success').addClass('d-flex');
                $('.reset-password-form__buttons--login').show();
            },
            error: function (error) {
                $('.reset-password-form__error__text').text(
                    translations[lang].reset_password_errors[error.status]
                );
                $('.reset-password__hint--info').hide();
                $('.material-icons.error').show();
                $('.reset-password-form__error').addClass('d-flex');
            }
        });
    }

    function redirectToLogin() {
        window.location.href = '/';
    }

    // Redirect to login page btn
    $(".reset-password-form__buttons--login").click(redirectToLogin);

    // SUSPENDED ACCOUNT PAGE -----------------------------------------------------

    // Redirect to login page btn
    $(".redirect-btn").click(redirectToLogin);
});
