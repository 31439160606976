export const language = window.navigator.userLanguage || window.navigator.language;
export const languages = ['pl', 'en', 'ru'];

export const translations = {
  pl: {
    title_access_denied: 'Dostęp zabroniony',
    title_backoffice: 'Panel administracyjny',
    title_reset_password: 'Resetuj hasło',
    title_suspended_account: 'Konto wyłączone',
    title: 'Logowanie do Strefy Klienta',
    email: 'login',
    login: 'login',
    email_error: 'Wprowadż poprawny adres email.',
    password: 'hasło',
    submit: 'zaloguj się',
    submit_reset: 'Zresetuj hasło',
    reset_password_errors: {
      400: "Błąd walidacji",
      404: "Nie znaleziono uzytkownika",
      408: "Czas połączenia z serwerem mailowym minął",
      500: "Bład resetu hasła",
      503: "Brak połączenia z bazą danych"
    },
    empty_control: 'Wypełnij to pole.',
    reset_password_btn: 'Resetuj hasło',
    back_to_login_btn: 'Powrót do logowania',
    reset_password_hint: 'Podaj swój login. Link do zmiany hasła zostanie wysłany na podany adres email powiązany z tym loginem.',
    reset_password_success: 'Jeśli użytkownik istnieje, wysłaliśmy email z linkiem do ustawienia nowego hasła. Aby zmienić hasło, otwórz otrzymaną wiadomość i kliknij podany link.',
    lang_select: "Wybór języka strony",
    menu_select: "Przełącz menu",
    suspended_account_content: 'Poniższe konto zostało wyłączone. Jeżeli mogło to nastąpić przez pomyłkę prosimy o kontakt z biurem obsługi klienta.',
    suspended_account_redirect_btn: 'Powróć do strony logowania',
    ip_whitelist_issue_content: 'Nie masz dostępu do danego zasobu. Skontaktuj się z administratorem.',
    ip_whitelist_issue_redirect_btn: 'Powrót do logowania',
    twofa_title: 'Wprowadź hasło SMS',
    twofa_label: 'hasło SMS',
    twofa_submit: 'Zatwierdź',
    twofa_code_sent: 'Wysłano kod SMS.',
    twofa_reset_btn: 'Nie otrzymałeś kodu? Wyślij ponownie.',
    twofa_reset_btn_time: 'Musi upłynąć jeszcze ',
    twofa_reset_btn_time_end: ' sek.',
    twofa_reset_error_bad_request: 'Nie udało się wysłać kodu',
    unexpected_error: "Niespodziewany błąd",
    "2fa_invalid_code": "Kod jest nieprawidłowy: spróbuj wprowadzić go ponownie lub wygeneruj nowy kod.",
    phone_number_empty: "Pusty numer telefonu",
    phone_number_invalid_format: "Nieprawidłowy format numeru telefonu",
    phone_number_foreign: "Zagraniczny numer telefonu",
    sms_text_empty: "Tekst SMS pusty",
    sms_delivery_failed: "Nie udało się dostarczyć SMS-a",
    sms_gateway_rejected_phone_number: "Błędny numer telefonu, nie można przesłać wiadomości SMS z kodem. \n Prosimy o kontakt z BOK.",
    sms_server_response_invalid_format: "Nieprawidłowy format odpowiedzi serwera SMS",
    authentication_failure_bad_credentials: "Użytkownik nie istnieje, bądź hasło nieprawidłowe.",
    backoffice_user_without_npk_role: "Konto istnieje w AD, nie ma jednak ustawionego dostępu do Strefy Klienta - prosimy o kontakt z działem IT",
    authentication_failure_active_directory_not_available: "Active Directory niedostępne",
    authentication_failure_missing_user_authorities: "Brak uwierzytelniania brakujące uprawnienia użytkownika",
    session_expired: "Sesja wygasła. Zaloguj się ponownie.",
    error_500: "Wewnętrzny błąd serwera.",
    error_500_support: "Jeżeli problem będzie się powtarzał, prosimy o kontakt z Biurem Obsługi Klienta.",
    error_404: "Przykro nam, strona o podanym URL nie istnieje.",
    error_login: "Przepraszamy, wystąpił problem podczas logowania.",
    error_login_support: "Spróbuj ponownie później.",
    invalid_token_title: "Link do ustawienia hasła jest nieaktywny",
    invalid_token_content: "Minął zbyt długi okres od wygenerowania linku do ustawienia nowego hasła. \n Proszę przejść do strony logowania, aby wygenerować nowy link.",
    invalid_token_go_to_login_btn: "Przejdź do strony logowania",
    send_email_on_first_login: "Drogi użytkowniku, logujesz się po raz pierwszy do naszego nowego portalu Strefa klienta. Na Twój adres wysłaliśmy mail z informacją dotyczącą logowania",
    cookie_policy_text_I: "Ta strona korzysta z plików cookies opisanych w ",
    cookie_policy_text_url: "Polityce cookies.",
    cookie_policy_text_II: "Korzystając z serwisu, wyrażasz zgodę na przetwarzanie niezbędnych plików technicznych.",
    privacy_policy_text_I: "Po wpisaniu przez Ciebie danych do logowania lub rejestracji zgody na pliki cookies, zostaną one powiązane z Twoim kontem. Przejdź do ",
    privacy_policy_text_url: "Polityki prywatności",
    privacy_policy_text_II: "aby zapoznać się z informacjami o przetwarzaniu Twoich danych osobowych.",
    cookie_configuration: "Ustawienia plików cookies możesz zmienić w dowolnym momencie. Jednak rezygnacja z niektórych plików może wpłynąć na poprawne działanie strony i wygodę przeglądania.",
    cookie_type_technical_label: "Pliki techniczne",
    cookie_type_advertisement_label: "Pliki reklamowe",
    cookie_type_analytics_label: "Pliki analityczne",
    cookie_type_technical_info: " – pliki, które są absolutnie niezbędne do prawidłowego funkcjonowania witryny. Ta kategoria obejmuje wyłącznie pliki cookie, które zapewniają podstawowe funkcje i zabezpieczenia witryny, nie przechowują natomiast żadnych danych osobowych.",
    cookie_type_advertisement_info: " – to pliki cookies umożliwiające dostarczanie dopasowanych treści reklamowych.",
    cookie_type_analytics_info: " – są to pliki wykorzystywane do agregacji danych użytkowych. Dzięki nim mierzymy i poprawiamy wydajność serwisu.",
    cookie_add_info: "Brak zgody na pliki analityczne przy wyrażeniu zgody na pliki reklamowe, spowoduje, że pliki reklamowe również nie zostaną aktywowane.",
    cookie_button_text: "Akceptuję",
    contact_info: {
      email: "E-mail: ",
      fax: "Fax: ",
      main_text: "W przypadku jakichkolwiek pytań zapraszamy do kontaktu z Biurem Obsługi Klienta. Jesteśmy do Państwa dyspozycji 24/7/365.",
      phone: "Tel.: ",
      regulations_url: "https://www.atman.pl/regulamin-strefy-klienta-atman/",
      regulations_text: "Regulamin Strefy Klienta"
    },
    welcome_text: "Zarządzaj swoimi usługami w Atmanie"
  },
  en: {
    title_access_denied: 'Access denied',
    title_backoffice: 'Administration panel',
    title_reset_password: 'Reset password',
    title_suspended_account: 'Account disabled',
    title: 'Login to Customer zone',
    email: 'login',
    login: 'login',
    email_error: 'Enter valid email address.',
    password: 'password',
    submit: 'Login',
    submit_reset: 'Reset Password',
    reset_password_errors: {
      400: "Validation error",
      404: "User not found",
      408: "The connection time with the mail server has passed",
      500: "Password reset error",
      503: "No connection to the database"
    },
    empty_control: 'This field is required.',
    reset_password_btn: 'Reset password',
    back_to_login_btn: 'Back to Login ',
    reset_password_hint: 'Enter your login. A link to change the password will be sent to the email address associated with this login.',
    reset_password_success: 'If the user exists, we have sent an email with a link to set a new password. To change your password, open the message you received and click on the link provided.',
    lang_select: "Select page language",
    menu_select: "Toggle menu",
    suspended_account_content: 'The following account has been disabled. If this could happen by mistake, please contact our customer service',
    suspended_account_redirect_btn: 'Return to the login page',
    ip_whitelist_issue_content: 'You do not have access to the resource. Contact the administrator.',
    ip_whitelist_issue_redirect_btn: 'Return to the login page',
    twofa_title: 'Enter SMS code',
    twofa_label: 'SMS password',
    twofa_submit: 'Confirm',
    twofa_code_sent: 'SMS code has been sent.',
    twofa_reset_btn: "You haven't received code? Send it again",
    twofa_reset_btn_time: 'Remaining time to resend ',
    twofa_reset_btn_time_end: ' sec.',
    twofa_reset_error_bad_request: 'Unable to resend code',
    unexpected_error: "Unexpected error",
    "2fa_invalid_code": "The code is incorrect: try to enter it again or generate a new code.",
    phone_number_empty: "Empty phone number",
    phone_number_invalid_format: "Invalid phone number format",
    phone_number_foreign: "Foreign phone number",
    sms_text_empty: "SMS text empty",
    sms_delivery_failed: "SMS delivery failed",
    sms_gateway_rejected_phone_number: "Invalid phone number, cannot send SMS with code. \n Please contact BOK.",
    sms_server_response_invalid_format: "SMS server response invalid format",
    authentication_failure_bad_credentials: "The user does not exist or the password you provided is incorrect.",
    backoffice_user_without_npk_role: "Konto istnieje w AD, nie ma jednak ustawionego dostępu do Strefy Klienta - prosimy o kontakt z działem IT",
    authentication_failure_active_directory_not_available: "Authentication failure active directory not available",
    authentication_failure_missing_user_authorities: "Authentication failure missing user authorities",
    session_expired: "Session expired. Please log in again.",
    error_500: "Internal server error.",
    error_500_support: "Please contact our support if the problem persists.",
    error_404: "We are sorry, this page does not exist.",
    error_login: "We are sorry, an error occurred while logging in.",
    error_login_support: "Please try again later.",
    invalid_token_title: "The link to set the password is expired",
    invalid_token_content: "Too long period of time has passed since the link was generated to set a new password. \n Please go to the login page to generate a new link.",
    invalid_token_go_to_login_btn: "Go to login page",
    send_email_on_first_login: "Dear user, you are logging in for the first time to our new portal Client Area. We've sent logging instructions to your email address.",
    cookie_policy_text_I: "This site uses cookies as described in the ",
    cookie_policy_text_url: "Cookie Policy.",
    cookie_policy_text_II: "By using the site, you consent to the processing of the necessary technical files.",
    privacy_policy_text_I: "If you enter your login information or register your consent to cookies, they will be associated with your account. Please see the ",
    privacy_policy_text_url: "Privacy Policy",
    privacy_policy_text_II: "for information regarding the processing of your personal information.",
    cookie_configuration: "You can change your cookie preferences at any time. Please be aware, however, that turning off certain cookies may affect some of the site’s features and your browsing experience.",
    cookie_type_technical_label: "Technical files",
    cookie_type_advertisement_label: "Advertising files",
    cookie_type_analytics_label: "Analytical files",
    cookie_type_technical_info: " – cookies that are absolutely necessary for the proper functioning of the site. This category includes only cookies that provide basic functionality and security for the site, but do not store any personal information.",
    cookie_type_advertisement_info: " – these are cookies that allow us to deliver customized advertising content.",
    cookie_type_analytics_info: " – these are cookies used to aggregate usage data. They allow us to measure and improve the performance of the site.",
    cookie_add_info: "Please note that if you do not accept the analytics files while accepting the advertising files, the advertising files will not be activated.",
    cookie_button_text: "Accept",

    contact_info: {
      email: "E-mail: ",
      fax: "Fax: ",
      main_text: "If you have any questions, please contact the Customer Service Office. We are at your disposal 24/7/365.",
      phone: "Phone: ",
      regulations_url: "https://www.atman.pl/atman-customer-zone-rules/",
      regulations_text: "Customer Zone Rules"
    },
    welcome_text: "Manage your services in Atman"
  },
  ru: {
    title_access_denied: 'Доступ запрещён',
    title_backoffice: 'Панель управления',
    title_reset_password: 'Восстановление доступа',
    title_suspended_account: 'Аккаунт отключён',
    title: 'Вход в Личный кабинет',
    email: 'логин',
    login: 'логин',
    email_error: 'Введите корректный адрес email.',
    password: 'пароль',
    submit: 'Войти',
    submit_reset: 'Восстановить',
    reset_password_errors: {
      400: "Ошибка валидации",
      404: "Пользователь не найден",
      408: "Время соединения с сервером истекло",
      500: "Ошибка восстановления пароля",
      503: "Нет удалось подключиться к базе данных"
    },
    empty_control: 'Поле не может быть пустым.',
    reset_password_btn: 'Забыли пароль?',
    back_to_login_btn: 'Вернуться на страницу входа',
    reset_password_hint: 'Введите свой логин. Ссылка для смены пароля будет выслана на адрес email связанный с введенным логином.',
    reset_password_success: 'Если данные введены верно, мы отправили email со ссылкой для смены пароля. Чтобы сменить пароль откройте полученное письмо и перейдите по указанной в нем ссылке.',
    lang_select: "Выбор языка страницы",
    menu_select: "Переключить меню",
    suspended_account_content: 'Указанный аккаунт неактивен. Если это произошло по ошибке свяжитесь, пожалуйста, с Отделом обслуживания клиентов.',
    suspended_account_redirect_btn: 'Вернуться на страницу входа',
    ip_whitelist_issue_content: 'Доступ к данному ресурсу запрещён. Обратитесь к Администратору',
    ip_whitelist_issue_redirect_btn: 'Вернуться на страницу входа',
    twofa_title: 'Введите код с СМС',
    twofa_label: 'Код с СМС',
    twofa_submit: 'Подтвердить',
    twofa_code_sent: 'СМС с кодом отправлено.',
    twofa_reset_btn: 'Не пришло СМС? Отправить ещё раз.',
    twofa_reset_btn_time: 'Должно пройти ещё ',
    twofa_reset_btn_time_end: ' сек.',
    twofa_reset_error_bad_request: 'Не удалось отправить код',
    unexpected_error: "Неизвестная ошибка",
    "2fa_invalid_code": "Неверный код: попробуйте ввести код ещё раз или сгенерируйте новый.",
    phone_number_empty: "Пустой номер телефона",
    phone_number_invalid_format: "Неверный формат номера телефона",
    phone_number_foreign: "Иностранный номер телефона",
    sms_text_empty: "Пустой текст СМС",
    sms_delivery_failed: "Не удалось доставить СМС",
    sms_gateway_rejected_phone_number: "[ru]Invalid phone number, cannot send SMS with code. \n Please contact BOK.",
    sms_server_response_invalid_format: "Неверный формат ответа сервера СМС",
    authentication_failure_bad_credentials: "Пользователь не существует или введен неверный пароль.",
    backoffice_user_without_npk_role: "Аккаунт найден в Active Directory, но он не имеет прав доступа к Личному кабинету - обратитесь в отдел IT",
    authentication_failure_active_directory_not_available: "Active Directory не доступен",
    authentication_failure_missing_user_authorities: "Неудачная аутентификация, недостаточно прав пользователя",
    session_expired: "Сессия закончилась. Пожалуйста войдите снова.",
    error_500: "Ошибка сервера.",
    error_500_support: "Если ошибка повторяется, пожалуйста, свяжитесь с нашим Оделом обслуживания клиентов.",
    error_404: "Нам жаль, страница не найдена.",
    error_login: "Нам жаль, появилась ошибка во время входа.",
    error_login_support: "Попробуйте повторить позднее.",
    invalid_token_title: "Ссылка для смены пароля неактивна",
    invalid_token_content: "Истекло время действия ссылки для введения нового пароля. \n Пожалуйста перейдите на страницу входа, чтобы сгенерировать новую ссылку.",
    invalid_token_go_to_login_btn: "Вернуться на страницу входа",
    send_email_on_first_login: "Уважаемый пользователь, Вы первый раз вошли в Личный кабинет. Мы отправили Вам email с информацией касающейся входа.",
    cookie_policy_text: "Данный веб-сайт использует файлы cookie для предоставления услуг в соответствии с",
    cookie_policy_text_url: "Политикой в отношении файлов cookie",
    privacy_policy_text: "Вы можете указать условия хранения файлов cookie или доступа к ним в Вашем браузере.",
    privacy_policy_text_url: "Политика конфиденциальности",
    cookie_button_text: "Закрыть",
    contact_info: {
      email: "Эл. почта: ",
      fax: "Факс: ",
      main_text: "Если у Вас возникнут какие-либо вопросы, обратитесь в Отдел обслуживания клиентов. Мы в Вашем распоряжении 24/7/365.",
      phone: "Телефон: ",
      regulations_url: "https://www.atman.pl/atman-customer-zone-rules/",
      regulations_text: "Customer Zone Rules"
    },
    welcome_text: "Управляйте своими услугами в Атмане"
  }
};
